<template>
  <div class="account">
    <div class="account__header d-flex justify-content-between">
      <div>&nbsp;</div>
      <div
        class="account__header__items d-flex align-items-center justify-content-between"
      >
        <div class="account__logo"></div>
      </div>
    </div>

    <div class="account__container">
      <div class="account__left">
        <div class="account__left__bg"></div>
      </div>
      <div class="account__right d-flex flex-column">
        <div class="account__form__container vh-100">
          <div class="account__form__wrapper d-flex flex-column">
            <div class="account__head">
              <!-- <h1 class="account__head__title">Welcome</h1> -->
            </div>
            <div class="account__body">
              <transition name="fade" mode="out-in">
                <div>
                  <h2 class="success--header">Awesome!!</h2>

                  <h4 class="success--sub text--600">Check Your Email</h4>

                  <p class="success--text text--600">
                    We are happy to say that everything went smoothly. Your
                    login URL and details have been sent to your email. Take
                    some time to set up the application.
                  </p>

                  <div class="d-flex mt-5">
                    <button
                      data-dismiss="#confirmModal"
                      class="button form__button form__button--lg w-30 save-changes"
                      @click="goToLogin"
                    >
                      Proceed to login
                    </button>
                  </div>

                  <div class="account__info">
                    <div
                      class="account__info__container d-flex align-items-center"
                    >
                      <div class="account__info__icon">
                        <img src="assets/img/help.svg" alt="" srcset="" />
                      </div>
                      <div class="">
                        <span class="account__text"
                          >Expert support available to you 24 /7.</span
                        >
                        <a
                          href="mailto:support@bookeet.io"
                          class="account__text account__info__link text--capital"
                        >
                          Contact Support
                        </a>
                      </div>
                      <!-- <div class="account__info__logo">
                        <img src="assets/img/logo-black.svg" alt="" srcset="" />
                      </div> -->
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OnboardingSuccess",
  methods: {
    goToLogin() {
      const domain = window.localStorage.getItem("domain");
      const url = process.env.VUE_DOMAIN_URL || "proleanfinancials.biz/";
      const realDomain = `http://${domain.toLowerCase()}.${url}`;
      location.replace(realDomain);
    },
  },
};
</script>
<style scoped lang="scss">
.success {
  &--header {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #00ba88;
    font-family: Montserrat-bold;
  }

  &--sub {
    font-size: 23px;
    line-height: 48px;
    letter-spacing: -0.0115em;
  }

  &--text {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.0115em;
    color: #4e4e4b;
    opacity: 0.4;
    width: 91%;
  }
}
.account__info {
  margin-top: 80px;
}
</style>
